<template>
    <div class="message-box dialog-mask trusted-device-dialog">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">{{ $t('trusted-device-modal.title') }}</h4>
                </div>
                <div class="modal-body">
                    <p class="modal-desc">
                        {{ $t('trusted-device-modal.description')
                        }}<span class="warning">{{ $t('trusted-device-modal.warning') }}</span>
                    </p>
                    <p class="modal-sub-desc">
                        {{ $t('trusted-device-modal.sub-description') }}
                    </p>
                    <div class="input-container">
                        <label for="deviceName">{{ $t('trusted-device-modal.input-label') }}</label>
                        <FormInput
                            id="deviceName"
                            v-model="$v.deviceName.$model"
                            :has-error="submitted && $v.deviceName.$error"
                            :override-error-text="$t(inputDeviceNameErrorText, [1, 128])"
                        >
                        </FormInput>
                    </div>
                </div>
                <div class="modal-footer">
                    <ModalMultiButtons
                        :btn-primary-text="
                            `${
                                type !== 'rememberMe'
                                    ? $t('trusted-device-modal.add')
                                    : $t('trusted-device-modal.confirm')
                            }`
                        "
                        btn-primary-id="add-device"
                        btn-secondary-id="cancel"
                        :btn-secondary-text="
                            `${type !== 'rememberMe' ? $t('cancel') : $t('trusted-device-modal.ask-later')}`
                        "
                        :primary-button-click="() => close(true)"
                        :secondary-button-click="() => close(false)"
                    ></ModalMultiButtons>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ModalMultiButtons, FormInput } from '@arena/toolkit';
import { required, minLength, maxLength } from 'vuelidate/lib/validators';
import modalEscMixin from '@/mixins/modalEscMixin';

export default {
    components: {
        ModalMultiButtons,
        FormInput,
    },
    mixins: [modalEscMixin],
    props: {
        type: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            deviceName: '',
            submitted: false,
        };
    },
    validations: {
        deviceName: {
            required,
            minLength: minLength(1),
            maxLength: maxLength(128),
        },
    },
    computed: {
        inputDeviceNameErrorText() {
            if (!this.$v.deviceName.required) {
                return 'error.input.trusted-device-name';
            }
            if (!this.$v.deviceName.maxLength) {
                return 'error.input.length.device-name';
            }
            return 'error.input.not-valid-device-name';
        },
    },
    mounted() {
        dataLayer.push({
            event: 'Trusted Device Confirmation Modal Viewed',
        });
    },
    created() {
        this.getSuggestDeviceName();
        this.handleEscCallbackToMixin = () => this.close(false);
    },
    methods: {
        getSuggestDeviceName() {
            this.$accountAPI
                .get('/no-auth/trusted-device/name/suggestion', {
                    params: { is_launcher: this.$store.state.isApplication },
                })
                .then((data) => {
                    this.deviceName = data.suggestion;
                });
        },
        close(rememberDevice) {
            if (this.type === 'rememberMe') {
                dataLayer.push({
                    event: 'Trusted Device Confirmation Modal Resolved',
                    trustedDeviceResolution: rememberDevice ? 'Confirmed' : 'Cancelled',
                });
            }
            if (rememberDevice) {
                this.$v.$touch();
                this.submitted = true;
                if (!this.$v.$invalid) {
                    this.$close({ rememberDevice: rememberDevice, deviceName: this.deviceName });
                }
            } else {
                this.$close({ rememberDevice: rememberDevice });
            }
        },
    },
};
</script>

<style lang="less" scoped>
.trusted-device-dialog {
    .modal-desc {
        margin-bottom: 10px;
        .warning {
            color: #e63f57;
            margin: 0;
            text-transform: capitalize;
        }
    }
    .input-container {
        padding-top: 16px;
    }
    .modal-footer {
        padding-top: 0px;
    }
}
</style>
