<template>
    <div class="message-box dialog-mask">
        <div class="modal-dialog" data-cy="error-modal">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 v-t="{ path: errorTitle }" class="modal-title"></h4>
                </div>
                <div class="modal-body">
                    <!-- TODO: v-html can lead to XSS attacks and should be avoided  -->
                    <!-- eslint-disable-next-line vue/no-v-html -->
                    <p v-html="$t(errorMessageId, { errorCode: errorCode, lineBreak: '<br />', ...i18nData })"></p>
                    <i18n v-if="showSupport" path="error.try_again_or_support" tag="p">
                        <template v-slot:support>
                            <a href="https://playersupport.nexon.com/hc/en-us/" target="_blank" rel="external">{{
                                $t('support')
                            }}</a>
                        </template>
                    </i18n>
                </div>
                <div class="modal-footer">
                    <div class="col-xs-6 pull-right">
                        <FormButton
                            id="modalPrimaryButtonId"
                            data-cy="error-modal-close"
                            class="btn-black"
                            :button-click="click"
                            :label="$t(closeLabel)"
                        >
                        </FormButton>
                    </div>
                    <div class="clearfix"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { FormButton } from '@arena/toolkit';
import modalEscMixin from '@/mixins/modalEscMixin';

export default {
    components: {
        FormButton,
    },
    mixins: [modalEscMixin],
    props: {
        errorCode: {
            type: [String, Number],
            default: '00000',
        },
        closeLabel: {
            type: String,
            default: 'close',
        },
        i18nData: {
            type: Object,
            default: () => ({}),
        },
    },
    computed: {
        errorMessageId() {
            return (
                this.checkI18n(`error.form.${this.errorCode}`) ||
                this.checkI18n(`error.modal.${this.errorCode}.desc`) ||
                'error.form.GENERAL'
            );
        },
        errorTitle() {
            return this.checkI18n(`error.modal.${this.errorCode}.title`) || 'dialog.title.error';
        },
        showSupport() {
            return this.errorMessageId === 'error.form.GENERAL';
        },
    },
    created() {
        this.handleEscCallbackToMixin = this.click;
    },
    methods: {
        click() {
            this.$close(true);
        },
        checkI18n(id) {
            return id !== this.$t(id) ? id : false;
        },
    },
};
</script>
