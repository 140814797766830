export default {
    namespaced: true,
    state: {
        newsletterList: [],
    },
    actions: {
        async fetchNewsletterList({ rootGetters, dispatch, commit }) {
            await dispatch('country/checkRegionInfo', '', { root: true });
            const { data } = await this._vm.$cdnAPI.getGameList(rootGetters['country/cmsRegionCode']),
                newsletterList = newsletterLists[environment.region],
                length = newsletterList.length,
                processedList = [];
            [...data].map((game) => {
                const mobileGame = getMobileGamesNewsletterInfo(game.name);
                if (mobileGame) {
                    game.productID = process.env.VUE_APP_ENV === 'dev' ? mobileGame.devId : mobileGame.prodId;
                    game.isNewsLetterMobileGame = true;
                }
            });

            for (let i = 0; i < length; i++) {
                const newsletter = newsletterList[i],
                    id = getNewsletterId(newsletter),
                    processedNewsletter = {
                        id,
                        name: '',
                        show: !isLegacyMobileAllGamesNewsletter(id),
                        isNewsOffersNewsletter: !!isNewsOffersNewsletter(id),
                        isMobileGame: false,
                        isGeneralNewsletter: !!isGeneralNewsletter(id),
                        isLegacyMobileSelectValue: !!isLegacyMobileAllGamesNewsletter(id),
                        initDependentOnNewsOffers: false,
                    };
                if (isGeneralNewsletter(id)) {
                    processedNewsletter.name = isLegacyMobileAllGamesNewsletter(id)
                        ? 'mobile-games'
                        : 'news-special-offers';
                } else {
                    const match = data.find((game) => game.productID === newsletter.prodId);
                    if (match) {
                        processedNewsletter.name = match.name;
                        processedNewsletter.initDependentOnNewsOffers = newsletter.initDependentOnNewsOffers;
                        processedNewsletter.isMobileGame = match.isNewsLetterMobileGame;
                    }
                }
                processedList.push(processedNewsletter);
            }
            commit('SET_NEWSLETTER_LIST', processedList);
        },
    },
    mutations: {
        SET_NEWSLETTER_LIST(state, list) {
            state.newsletterList = list;
        },
    },
};

const getNewsletterId = (newsletter) => newsletter[`${process.env.VUE_APP_ENV === 'dev' ? 'devId' : 'prodId'}`];

const isLegacyMobileAllGamesNewsletter = (id) => id === getNewsletterId(legacyMobileAllNewsletter);

const getMobileGamesNewsletterInfo = (gameName) => {
    const regexp = new RegExp(`^${gameName.replace('+', '\\+')}$`);
    return newsLetterMobileGames.find((mobileGameInfo) => regexp.test(mobileGameInfo.umbracoGameName));
};

const isNewsOffersNewsletter = (id) => id === getNewsletterId(newsOffers);

const isGeneralNewsletter = (id) => isLegacyMobileAllGamesNewsletter(id) || isNewsOffersNewsletter(id);

const maplestory = {
    prodId: '10100',
    devId: '10100',
};

const mabinogi = {
    prodId: '10200',
    devId: '10200',
};

const vindictus = {
    prodId: '10300',
    devId: '10300',
};

const legacyMobileAllNewsletter = {
    prodId: '59750',
    devId: '31014',
};

const newsOffers = {
    prodId: '59749',
    devId: '31015',
};

const moonlightBlade = {
    prodId: '50202',
    devId: '50202',
};

const maplestoryWorld = {
    prodId: '59794',
    devId: '59794',
    initDependentOnNewsOffers: true,
};

const maplestoryMobile = {
    prodId: '59789',
    devId: '59789',
};

const blueArchive = {
    prodId: '59754',
    devId: '59754',
};

const kartriderRushPlus = {
    prodId: '59752',
    devId: '59752',
};

const newsLetterMobileGames = [
    {
        umbracoGameName: 'MapleStory Worlds',
        ...maplestoryWorld,
    },
    {
        umbracoGameName: 'MapleStory M',
        ...maplestoryMobile,
    },
    {
        umbracoGameName: 'Blue Archive',
        ...blueArchive,
    },
    {
        umbracoGameName: 'KartRider Rush+',
        ...kartriderRushPlus,
    },
];

const newsletterLists = {
    global: [
        maplestory,
        mabinogi,
        vindictus,
        maplestoryWorld,
        maplestoryMobile,
        blueArchive,
        kartriderRushPlus,
        legacyMobileAllNewsletter,
        newsOffers,
    ],
    tw: [
        moonlightBlade,
        vindictus,
        maplestoryMobile,
        blueArchive,
        kartriderRushPlus,
        legacyMobileAllNewsletter,
        newsOffers,
    ],
    th: [maplestoryMobile, blueArchive, kartriderRushPlus, legacyMobileAllNewsletter, newsOffers],
    sea: [maplestoryMobile, blueArchive, kartriderRushPlus, legacyMobileAllNewsletter, newsOffers],
};
