import launcherAPI from '@/modules/launcher';
import { loadLanguageAsync } from '@/plugins/i18n';
import store from '@/store';
import Vue from 'vue';
import Router from 'vue-router';
import routes from './routes';

// copy from https://github.com/vuejs/vue-router/issues/2881#issuecomment-520554378
const swapFunc = (original) =>
    function push(location, onResolve, onReject) {
        if (onResolve || onReject) return original.call(this, location, onResolve, onReject);
        return original.call(this, location).catch((err) => {
            if (Router.isNavigationFailure(err)) {
                // resolve err
                return err;
            }
            // rethrow error
            return Promise.reject(err);
        });
    };
Router.prototype.push = swapFunc(Router.prototype.push);
Router.prototype.replace = swapFunc(Router.prototype.replace);

Vue.use(Router);
const router = new Router({
    mode: 'history',
    base: '/account/',
    routes,
    // when page transitioning, need to fix scroll position
    scrollBehavior: (to, from, savedPosition) => {
        if (to.hash) {
            return {
                selector: to.hash,
            };
        }
        return !savedPosition ? { x: 0, y: 0 } : savedPosition;
    },
});

// convert temporary querystring to cookie or store and remove it
function convertQueryToStoreOrCookie(to) {
    const prodId = to.query.prod_id,
        marketingLanding = to.query.landing,
        partnerKey = to.query.partnerkey || to.query.partner_key;

    if (prodId) {
        if (marketingLanding) {
            store.commit('setProductId', `${prodId} landing`);
        } else {
            store.commit('setProductId', prodId);
        }
        Vue.cookie.set('ProdId', prodId);
    }
    if (partnerKey) {
        Vue.cookie.set('partner_key', partnerKey);
        store.commit('setPartnerKey', partnerKey);
    }

    if (to.query.app_type) {
        store.commit('setAppType', to.query.app_type);
    }

    if (to.query.app_id) {
        store.commit('setProductId', to.query.app_id);
    }

    if (to.name === 'login' || to.name === 'create-account') {
        if (to.query['marvel-machine'] === 'true') {
            Vue.cookie.set('FromMarvelMachine', true);
        } else {
            if (!to.query.return_url || (to.query.return_url && !to.query.return_url.includes('marvel-machine'))) {
                Vue.cookie.set('FromMarvelMachine', false);
            }
        }
    }
}

Object.filter = (obj, predicate) => Object.fromEntries(Object.entries(obj).filter(predicate));
router.beforeEach((to, from, next) => {
    if (
        from.name === 'self-reset-password' &&
        to.name === 'login' &&
        to.params.newPassword &&
        to.params.isSetupPassword
    ) {
        return next();
    }
    if (from.query.unified) {
        if (to.name === 'login') {
            return next({
                name: 'unified-login',
            });
        }
        if (to.name === 'create-account') {
            return next({
                name: 'unified-signup',
            });
        }
    }

    // carryover querystring
    const coQueryKeys = [
        'route_to', // global
        'return_url', // global
        ...to.matched.reduce(
            (prev, cur) => (cur.meta.coQuery ? [...new Set([...prev, ...cur.meta.coQuery])] : prev),
            []
        ),
    ];
    const toQueryKeys = Object.keys(to.query);
    const fromQuery = Object.filter(from.query, ([name]) => coQueryKeys.includes(name) && !toQueryKeys.includes(name));

    if (to.name === fromQuery.route_to) {
        delete fromQuery.route_to;
        delete from.query.route_to;
    }

    if (Object.keys(fromQuery).length > 0) {
        return next({ ...to, query: { ...fromQuery, ...to.query } });
    }
    ///
    const routeLang = to.params.locale,
        referrerUrl = document.referrer,
        returnUrl = to.query.return_url;

    // membership apis are referencing i18next cookie for language code
    if (
        routeLang &&
        routeLang.length == 2 &&
        Vue.cookie.get('i18next') !== routeLang &&
        to.name !== 'login-move-to-zendesk'
    ) {
        Vue.cookie.set('i18next', routeLang);
    }

    // transform a document.referrer to 'return_url' querystring if it's our site
    // return_url query has more high priority than document.referrer
    if (!returnUrl && referrerUrl) {
        const urlO = new URL(referrerUrl, document.baseURI),
            pathName = urlO.pathname,
            redirectDomain = urlO.hostname.toLowerCase();
        if (
            (redirectDomain.endsWith('nexon.com') ||
                redirectDomain.endsWith('nexon.net') ||
                redirectDomain.endsWith('zendesk.com')) &&
            !(redirectDomain === location.hostname.toLowerCase() && pathName.startsWith('/account/'))
        ) {
            return next({ ...to, query: { ...to.query, return_url: referrerUrl } });
        }
    }
    // <html lang="{routeLang}">
    document.getElementsByTagName('html')[0].lang = routeLang;
    if (to.name) {
        document.body.className = to.name.toLowerCase().replace('.', ' '); // add route name as class name(s)
    }

    convertQueryToStoreOrCookie(to);

    const targetUrl = `/account${to.fullPath}`; // it should be relative to current host because of supporting region selector

    if (to.matched.some((record) => record.meta.requireSettingsInfo)) {
        store.commit('setLoading', true);
        Promise.all([loadLanguageAsync(routeLang), store.dispatch('settings/refreshAccountData')])
            .then(
                () => {
                    store.commit('login/setIsLoggedIn', true);
                    next();
                },
                ({ errorCode }) => {
                    store.commit('login/setIsLoggedIn', false);
                    // console.log('router:', 'requireSettingsInfo', errorCode);
                    if (errorCode === 20100 || errorCode === 20102) {
                        const isLauncher = launcherAPI.isAppMode();
                        if (isLauncher) {
                            window.parent.postMessage('portalban.logout', '*');
                        } else {
                            next({ name: 'logout', params: to.params });
                        }
                    } else {
                        next({
                            name: 'login',
                            query: {
                                ...to.query,
                                return_url: targetUrl,
                            },
                            params: to.params,
                        });
                    }
                }
            )
            .finally(() => {
                store.commit('setLoading', false);
            });
        return;
    }

    if (to.matched.some((record) => record.meta.requiresLogin || record.meta.requiresNotLogin)) {
        store.commit('setLoading', true);
        Promise.all([loadLanguageAsync(routeLang), store.dispatch('login/checkLogin')])
            .then(() => {
                if (store.state.login.isLoggedIn) {
                    if (to.matched.some((record) => record.meta.requiresNotLogin)) {
                        console.log('router:', 'requiresNotLogin - redirect back', to.query);
                        if (to.query.route_to) next({ ...to, name: to.query.route_to });
                        else store.dispatch('defaultRedirectHandler', to.query);
                    } else {
                        next();
                    }
                } else {
                    if (to.matched.some((record) => record.meta.requiresLogin)) {
                        next({
                            name: 'login',
                            query: {
                                ...to.query,
                                return_url: targetUrl,
                            },
                            params: to.params,
                        });
                    } else {
                        next();
                    }
                }
            })
            .catch((err) => {
                console.error('router:requiresLogin', err);
            })
            .finally(() => {
                store.commit('setLoading', false);
            });
        return;
    }

    loadLanguageAsync(routeLang).then(() => next());
});

export default router;
