import Vue from 'vue';
import loginRoutes from './login';
import settingRoutes from './setting';
import globalRoutes from './global';
import { loadView } from './_utils';
import rootViewComponent from '@/views/Root';
import selfService from './self-service';
import store from '@/store';
import unified from './unified';
import gamescaleRoutes from './gamescale';

export default [
    {
        path: '/:locale([a-z]{2})',
        component: rootViewComponent,
        meta: { coQuery: ['unified'] },
        children: [
            {
                path: 'reset-password',
                name: 'reset-password',
                redirect: { name: 'self-service' }, // redirect to self-service/reset-password for lagacy link in user email
            },
            {
                path: 'reset-password-legacy',
                name: 'reset-password-legacy',
                component: loadView('reset-password-legacy'),
            },
            {
                path: 'authorize',
                name: 'authorize',
                component: loadView('authorize'),
                meta: { requiresLogin: true },
            },
            {
                path: 'create',
                name: 'create-account',
                component: loadView('create/create-account'),
                meta: { requiresNotLogin: !store.state.isApplication, coQuery: ['simple_signup', 'marvel-machine'] },
            },
            {
                // signup account email verification complete page (account signup success page)
                path: 'create/complete',
                name: 'create-account-complete',
                component: loadView('create/create-account-complete'),
                meta: { coQuery: ['parental_flow_mode'] },
            },
            {
                path: 'create/parent-consent-required',
                name: 'parent-consent-required',
                component: loadView('create/parent-consent-required'),
            },
            {
                // parent signup url is used for parent consent required email
                path: 'create/parent',
                name: 'create-parent-account',
                component: loadView('create/create-account'),
            },
            {
                // tpa account creation for legaxy steam games
                path: 'create-tpa',
                name: 'create-tpa',
                component: loadView('create/create-tpa'),
            },
            {
                path: 'create/verify/code-tpa',
                name: 'verify-code-tpa',
                component: loadView('create/verify-code-tpa'),
            },
            {
                path: 'verify-phone',
                name: 'verify-phone',
                component: loadView('verify-phone'),
                props: true,
                meta: { coQuery: ['parental_flow_mode'] },
            },
            {
                path: 'healup',
                name: 'healup',
                component: loadView('healup-general'),
                props: true,
                meta: { coQuery: ['parental_flow_mode'] },
            },
            {
                path: 'link/:provider',
                name: 'link-third-party',
                component: loadView('link-third-party'),
                props: true,
            },
            {
                path: 'error',
                name: 'error',
                component: loadView('error'),
            },
            {
                path: 'maintenance',
                name: 'maintenance',
                component: loadView('maintenance'),
            },
            {
                path: 'newsletter/opt-out',
                name: 'opt-out',
                component: loadView('opt-out'),
            },
            {
                path: 'newsletter/sms-opt-in',
                name: 'sms-opt-in',
                component: loadView('sms-opt-in'),
                meta: { requiresLogin: true, requireSettingsInfo: true },
            },
            {
                path: ':from(setting)?/opt-in-sms',
                name: 'opt-in-sms',
                component: loadView('opt-in-sms'),
            },
            {
                path: 'pre-signup/newsletter/opt-out',
                name: 'pre-signup-opt-out',
                component: loadView('pre-signup-opt-out'),
            },
            {
                path: 'create-legacy',
                name: 'create-legacy',
                component: loadView('create-legacy'),
            },
            ...gamescaleRoutes,
            ...loginRoutes,
            ...settingRoutes,
            ...globalRoutes,
            ...selfService,
            ...unified,
            {
                path: '',
                redirect: { name: 'login' },
            },
        ],
    },
    {
        path: '*',
        name: 'error-redirect',
        redirect: (to) => {
            const paths = to.path.split('/'),
                cookieLang = Vue.cookie.get('i18next') || 'en';
            if (paths[1].length !== 2) {
                window.location = `/account/${cookieLang}${to.fullPath}`;
                return false;
            }
            // double language code path caused by web gateway's injection if the language code is not supported one
            if (paths[2] && paths[2].length === 2) {
                paths.splice(2, 1);
                window.location = `/account${paths.join('/')}`;
                return false;
            }
            return {
                path: `/${cookieLang}/error`,
            };
        },
    },
];
