import { loadView } from './_utils';
import rootViewComponent from '@/views/Root';

export default [
    {
        path: 'gamescale', // gamescale mobile sdk
        component: rootViewComponent,
        meta: { coQuery: ['unified'] },
        children: [
            {
                path: 'verify-sms',
                name: 'gamesacle-mobile-login-verify-sms',
                component: loadView('login/gamescale/verify-code-sms'),
                meta: { coQuery: ['parental_flow_mode'] },
            },
            {
                path: 'verify-code',
                name: 'gamesacle-mobile-login-verify-2fa',
                component: loadView('login/gamescale/verify-code-2fa'),
                meta: { coQuery: ['parental_flow_mode'] },
            },
        ],
    },
];
